import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { elementBelongsToTarget } from '../../shared/utils/utils';

@Component({
  selector: 'app-overlay-file-uploader',
  templateUrl: './overlay-file-uploader.component.html',
  styleUrls: ['./overlay-file-uploader.component.scss']
})
export class OverlayFileUploaderComponent implements OnInit, AfterViewInit {

  uploaderIsDisplayed: boolean;

  @ViewChild('content', { read: ViewContainerRef, static: false }) content: ViewContainerRef;
  @ViewChild('overlayFileUploader') overlayFileUploader: ElementRef;

  constructor(
    private cfr: ComponentFactoryResolver
  ) { }

  ngOnInit(): void {
    fromEvent(document, 'click')
      .subscribe((e) => {
        let manualUploadOpener = document.querySelectorAll('.btn-add-file')[0];
        if (manualUploadOpener != e.target && this.uploaderIsDisplayed == true) {
          if (elementBelongsToTarget(e.target, this.overlayFileUploader.nativeElement)) { this.uploaderIsDisplayed = false; }
        }
      })
  }

  ngAfterViewInit() {
    this.getFileUploader();
  }

  private async getFileUploader() {
    this.content.clear();
    const { FileUploaderComponent } = await import('../../../app-file/components/file-uploader/file-uploader.component');
    const { instance } = this.content.createComponent(this.cfr.resolveComponentFactory(FileUploaderComponent));

    instance.onDragEnter.subscribe(() => {
      this.uploaderIsDisplayed = true;
    });

    instance.onDragLeave.subscribe(() => {
      this.uploaderIsDisplayed = false;
    });

    instance.onDrop.subscribe(() => {
      this.uploaderIsDisplayed = false;
    })
  }
}
