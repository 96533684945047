<section class="card-box">
    <div class="card" [ngClass]="{'edit' : isEdit}">
        <button *ngIf="allowEdit" class="fs-button btn-edit" (click)="edit()"></button>

        <div *ngIf="!isEdit">
            <div class="card-logo">
                <div class="card-logo-strip"></div>
                <div class="card-logo-strip"></div>
                <div class="card-logo-strip"></div>
            </div>

            <div class="card-number">
                <h3 class="fw-bold">****</h3>
                <h3 class="fw-bold">****</h3>
                <h3 class="fw-bold">****</h3>
                <h3 *ngIf="!form.get('cardNumberLastDigits')?.value" class="fw-bold">****</h3>
                <h3 *ngIf="form.get('cardNumberLastDigits')?.value" class="fw-bold">{{ form.get('cardNumberLastDigits')?.value }}</h3>
            </div>

            <div class="card-holder-and-valid-to-date">
                <label class="label-1 fw-bold">{{ form.get('cardHolderName')?.value }}</label>
                <label *ngIf="form.get('cardExpireMonth')?.value" class="label-1 fw-bold">{{ form.get('cardExpireMonth')?.value }}/{{ form.get('cardExpireYear')?.value }}</label>
            </div>
        </div>

        <div *ngIf="isEdit">
            <form [formGroup]="form">
                <div class="card-number">
                    <label class="label-1 fw-medium">Card Number</label>
                    <div id="card-number"></div>
                </div>

                <div class="card-holder-exp-date-and-cvc">
                    <div class="card-holder">
                        <label class="label-1 fw-medium">Cardholder name</label>
                        <input type="text" formControlName="cardHolderName">
                    </div>
                    <div class="exp-date">
                        <label class="label-1 fw-medium text-uppercase">Exp</label>
                        <div id="card-exp"></div>
                    </div>
                    <div class="cvc">
                        <label class="label-1 fw-medium text-uppercase">CVC</label>
                        <div id="card-cvc"></div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</section>