import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalPositionType } from 'src/app/shared/models/modal-position.model';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  modalIsScrolled = false;
  modalIsShown = false;
  // position: ModalPositionType = ModalPositionType.center;  

  positions = ModalPositionType;
  position: ModalPositionType;

  @ViewChild('modalContainer') modalContainer: ElementRef;
  @ViewChild('modalContent', { read: ViewContainerRef }) modalContent: ViewContainerRef;

  @HostListener('window:resize', ['$event']) onResize(event) { this.maitainModalSctroll(); }
  @HostListener('document:click', ['$event']) clickout(event) {
    if (this.isDescendantOf(['modal-root'], event.target) &&
      !this.isDescendantOf(['c-container'], event.target) &&
      this.modalIsShown) {
      this.modalService.hideModal();
    }
  }

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    setTimeout(() => { this.modalService.modalContent = this.modalContent; }, 10);
    setTimeout(() => { this.maitainModalSctroll(); }, 10);

    this.modalService.onModalShow
      .subscribe((instance) => {
        this.modalIsShown = true;
        this.modalContent = instance;
        this.onModalOpen();
      });

    this.modalService.onModalClose
      .subscribe(() => {
        this.modalIsShown = false;
        this.modalContent = null;
        this.onModalClose();
      });

    this.modalService.onModalPositionSet
      .subscribe((position: ModalPositionType) => {
        this.position = position;
      });
  }

  private maitainModalSctroll() {
    this.modalIsScrolled = (this.modalContainer?.nativeElement.offsetHeight + 100) > window.innerHeight;
  }

  private isDescendantOf(descendedNames, child) {
    var node = child.parentNode;
    try {
      while (node != null) {
        let nodeClassNames = node.className ? node?.className?.split(' ') : [];
        if (descendedNames.indexOf(node.nodeName.toLowerCase()) != -1 ||
          (nodeClassNames.length > 0 && descendedNames.filter(v => nodeClassNames.includes(v)).length > 0)) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  private onModalOpen() {
    setTimeout(() => { this.maitainModalSctroll(); }, 5);
    document.getElementById('fs-body').classList.add('fixed');
  }

  private onModalClose() {
    document.getElementById('fs-body').classList.remove('fixed');
  }
}
