import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { elementBelongsToTarget } from 'src/app/core/shared/utils/utils';
import { ISelect } from '../../models/select.model';

@Component({
  selector: 'app-fs-select-two',
  templateUrl: './fs-select-two.component.html',
  styleUrls: ['./fs-select-two.component.scss']
})
export class FsSelectTwoComponent implements OnInit {

  @ViewChild('contentPanel') contentPanel: ElementRef;
  @ViewChild('selectorContent') selectorContent: ElementRef;

  @Input('label') label: string;
  @Input('items') items: ISelect[];
  @Output('onSelect') onSelect: EventEmitter<string | number> = new EventEmitter();

  searchString = '';

  contentHeight = 0;
  contentIsShown = false;

  get selectedItem() {
    return this.items.filter(x => x.selected)[0];
  }

  get unselectedItems() {
    return this.items.filter(x => x.selected != true && (this.searchString == '' || x.value.toString().toLowerCase().includes(this.searchString.toLowerCase())))
  }

  constructor() { }

  searchKeyUp() {
    this.contentHeight = this.contentIsShown ? this.contentPanel.nativeElement.offsetHeight : 0;
  }

  ngOnInit(): void {
    fromEvent(document, 'click')
      .subscribe((e) => {
        if (this.contentIsShown == true) {
          if (elementBelongsToTarget(e.target, this.selectorContent.nativeElement)) {
            this.toggle();
          }
        }
      })
  }

  selectItem(item) {
    console.log('selecting ');
    this.items.forEach(x => x.selected = false);
    item.selected = true;
    this.onSelect.emit(item.id);
    this.closeContent();
  }

  toggle() {
    this.contentIsShown = !this.contentIsShown;
    this.contentHeight = this.contentIsShown ? this.contentPanel.nativeElement.offsetHeight : 0;
  }

  private closeContent() {
    this.contentIsShown = false;
    this.contentHeight = 0;
  }
}
