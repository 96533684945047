<section>
    <div class="auth-layout-container">
        <div class="auth-header">
            <a href="">
                <img src="../../../../assets/fs-logo-lines.svg" alt="">
            </a>
            <div class="project-portal-name f-30">{{ projectName }}</div>
            <div class="project-portal f-30">Project Portal</div>
        </div>
        <div class="auth-body">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="auth-cover-container">
        <div class="line-patterns">
            <div class="line-patterns-layout">
                <div class="line-pattern"></div>
                <div class="line-pattern"></div>
                <div class="line-pattern"></div>
            </div>
        </div>
        <div class="cover-panel">
            <div>
                <h1> Big </h1>
                <h1> Ideas. </h1>
                <h1> Built </h1>
                <h1> Fast. </h1>
            </div>
            <a href="" class="logo">
                <img src="../../../../assets/fs-logo.svg" alt="">
            </a>
        </div>
    </div>
</section>