<article class="project-budget-info" #projectBudgetInfo [ngClass]="{'opened' : detailsAreOpened}">
    <label class="label-1 text-uppercase fw-medium">Budget estimate</label>

    <div class="budget-panel white-box-container">

        <div class="budget-actions">
            <app-fs-arrow-button class="budget-action"></app-fs-arrow-button>
            <app-fs-triangle-arrow-button (onClick)="toggleDetails()" class="budget-action">
            </app-fs-triangle-arrow-button>
        </div>

        <div class="budget-heading">
            <label class="label-1 fw-normal text-uppercase">from</label>
            <h2 class="fw-extra-bold text-uppercase">${{ totalBudget | fsnumber }}</h2>
        </div>

        <div class="budget-body">
            <app-budget-box-details-list #estimateBudgetBoxDetailsList [budgets]="budgets">
            </app-budget-box-details-list>
        </div>
    </div>
</article>