import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-estimate-sprint-box',
  templateUrl: './estimate-sprint-box.component.html',
  styleUrls: ['./estimate-sprint-box.component.scss']
})
export class EstimateSprintBoxComponent implements OnInit {

  @Input('totalSprints') totalSprints: number;
  @Input('totalWeeks') totalWeeks: number;

  constructor() { }

  ngOnInit(): void {
  }

}
