
export const environment = {
   production: false,
   circuitApiUrl: "https://fs-circuit-admin-develop.herokuapp.com",
   paymentApiUrl:"https://fs-module-payments-develop.herokuapp.com",
   notificationsApiUrl:"https://fs-module-notif-develop.herokuapp.com",
   landingWebUrl:"https://develop.faststartup.io",
   stripeKey:"pk_test_51ITrmTDWPf2enLeu6enSm5WiMs2st8RyPebXJaAGoAeRW3sQBP44MHngFSUU6B6BFS22CugjKzU7Q7EmO396sYVw00bEOWD6Hd",
   shareLink:"http://localhost:4200/estimate"
};
