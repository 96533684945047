<section>
    <form [formGroup]="form">
        <div class="payment-form vertical" [ngClass]="{'vertical' : vertical}">

            <!-- <app-fs-card [form]="form.controls.card" [label]="'Card'" (onCardChange)="cardChange($event)"
                (onEditClick)="editPaymentDetails()" [form]="form.controls.card" [viewState]="viewState"></app-fs-card> -->

            <div class="billing-address-box" *ngIf="viewState != 'edit'">
                <h6>Billing address</h6>
                <button class="btn-edit" (click)="editPaymentDetails()" *ngIf="!dataIsLoading"></button>
                <div class="billing-address-details">
                    <app-fs-loader *ngIf="dataIsLoading"></app-fs-loader>
                    <div *ngIf="!dataIsLoading">
                        <h5>{{ form.controls.address.get('name').value }}</h5>
                        <h5>{{ form.controls.address.get('addressLineOne').value }}</h5>
                        <h5>
                            {{ form.controls.address.get('addressLineTwo').value }},
                            {{ form.controls.address.get('state').value }}
                        </h5>
                        <h5>{{ form.controls.address.get('country').value }}</h5>
                    </div>
                </div>
            </div>

            <div class="billing-address-box" formGroupName="address" *ngIf="viewState == 'edit'">
                <h4>Billing address</h4>

                <app-fs-text-input [label]="'Name'" formControlName="name"></app-fs-text-input>
                <app-fs-text-input [label]="'Address Line 1'" formControlName="addressLineOne"></app-fs-text-input>
                <app-fs-text-input [label]="'Address Line 2'" formControlName="addressLineTwo"></app-fs-text-input>

                <div class="state-and-country-panel">
                    <app-fs-text-input [label]="'State'" formControlName="state"></app-fs-text-input>
                    <app-fs-text-input [label]="'Country'" formControlName="country"></app-fs-text-input>
                </div>

                <div class="btn-save-panel">
                    <app-fs-skew-button (onClick)="save()" [disabled]="isLoading || !form.valid">
                        Save
                    </app-fs-skew-button>
                </div>
            </div>

        </div>
    </form>
</section>