import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProjectService } from '../services/project.service';
import { SessionService } from '../services/session.service';
import { getProjectSlug } from '../utils/utils';


@Injectable({
  providedIn: 'root'
})
export class ProjectGuard implements CanActivate {

  constructor(
    private router: Router,
    private projectService: ProjectService,
    private sessionService: SessionService
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    // return of(true);

    let projectSlug = getProjectSlug();
    if (!projectSlug) {
      this.router.navigate(['/auth/join']);
      return of(true);
    } else {
      return this.projectService.get(getProjectSlug())
        .pipe(
          map(() => { return true }),
          catchError((err) => {
            this.sessionService._removeSession();
            this.router.navigate(['/not-found']);
            return of(false);
          })
        );
    }
  }
}
