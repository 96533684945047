import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fs-arrow-button',
  templateUrl: './fs-arrow-button.component.html',
  styleUrls: ['./fs-arrow-button.component.scss']
})
export class FsArrowButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
