import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-budget-box-details-list',
  templateUrl: './budget-box-details-list.component.html',
  styleUrls: ['./budget-box-details-list.component.scss']
})
export class BudgetBoxDetailsListComponent implements OnInit {

  @Input('budgets') budgets: any[];

  @ViewChild('budgetBodyPanel') budgetBodyPanel: ElementRef;

  budgetBodyHeight: number = null;
  budgetBodyIsOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleBudgetBody() {
    this.budgetBodyIsOpen = !this.budgetBodyIsOpen;
    this.budgetBodyHeight = this.budgetBodyIsOpen ? this.budgetBodyPanel.nativeElement.offsetHeight + 40 : 0;
  }
}
