import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BillingService } from 'src/app/app-billing/shared/services/billing.service';
import { ProfileService } from 'src/app/app-profile/shared/services/profile.service';
import { SessionService } from 'src/app/core/shared/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fs-card',
  templateUrl: './fs-card.component.html',
  styleUrls: ['./fs-card.component.scss']
})
export class FsCardComponent implements OnInit, OnInit {

  @Input('allowEdit') allowEdit: boolean;
  @Input('isEdit') isEdit: boolean;

  @Output('onBillingCardCreate') onBillingCardCreate: EventEmitter<string> = new EventEmitter();

  form: FormGroup;
  isLoading: boolean;

  private cardNumberIsValid: boolean;
  private expIsValid: boolean;
  private cvcIsValid: boolean;

  private stripe: Stripe;
  private stripeElements: StripeElements;

  @Output('onCardChange') onCardChange: EventEmitter<any> = new EventEmitter();
  @Output('onEditClick') onEditClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private billingService: BillingService,
    private profileService: ProfileService,
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    if (this.isEdit) {
      this.loadStripe();
    }
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isEdit.currentValue != changes.isEdit.previousValue && this.isEdit == true) {
      this.loadStripe();
    }
  }

  get canSave() {
    return this.cardNumberIsValid && this.expIsValid && this.cvcIsValid && this.form.valid;
  }

  edit() {
    this.onEditClick.emit();
  }

  save(addressName, addressLineOne, addressLineTwo, state, zipCode, country) {
    this.isLoading = true;
    const value = this.form.value;
    from(this.stripe.createToken(this.stripeElements.getElement('cardNumber'), {
      name: value.cardHolderName,
      address_line1: addressLineOne,
      address_line2: addressLineTwo,
      address_state: state,
      address_zip: zipCode,
      address_country: country
    })).pipe(
      switchMap((resp: any) => {
        return this.billingService.createBillingCard(resp.token?.id, addressName);
      }),
      switchMap(() => {
        return this.profileService.refreshProfile(this.sessionService.project.slug)
      })
    ).subscribe(() => {
      this.onBillingCardCreate.emit();
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    });
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      cardHolderName: [null, Validators.required],
      cardExpireMonth: [],
      cardExpireYear: [],
      cardNumberLastDigits: [],
    });

    if (this.sessionService.user.source) {
      this.getBilling();
    }
  }

  private getBilling() {
    this.billingService.getBillingCard()
      .subscribe((resp) => {
        this.form.patchValue({
          cardHolderName: this.isEdit ? '' : resp.cardHolderName,
          cardExpireMonth: resp.expMonth,
          cardExpireYear: resp.expYear,
          cardNumberLastDigits: resp.cardNumberLastDigits
        });
      }, err => { })
  }

  private async loadStripe() {
    this.stripe = await loadStripe(environment.stripeKey);

    this.stripeElements = this.stripe.elements({
      fonts: [
        {
          family: 'gilroy',
          src: 'url(https://develop.faststartup.io/Gilroy-900.ttf) format("truetype")',
          weight: 'normal'
        },
        {
          family: 'Open Sans',
          weight: '400',
          src: 'local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3ZBw1xU1rKptJj_0jans920.woff2) format("woff2")',
          unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215',
        },
      ]
    });

    let cvcAndExpiryStyle = {
      color: '#000000',
      fontSize: '14px',
      fontFamily: '"gilroy"',
      fontWeight: '700',
      lineHeight: '31px',
      padding: '5px 0 7px 0',
      '::placeholder': {
        color: 'transparent',
      },

      ':focus::placeholder': {
        color: 'transparent',
      }
    };

    let cardNumberStyle = {
      color: '#000000',
      fontSize: '24px',
      fontFamily: 'gilroy',
      fontWeight: '700',
      lineHeight: '31px',
      padding: '5px 0 7px 0',
      '::placeholder': {
        color: 'transparent',
      },

      ':focus::placeholder': {
        color: 'transparent',
      },
    };

    let card = this.stripeElements.create('cardNumber', { style: { base: cardNumberStyle, invalid: { color: 'red' } } });
    let exp = this.stripeElements.create('cardExpiry', { style: { base: cvcAndExpiryStyle, invalid: { color: 'red' } } });
    let cvc = this.stripeElements.create('cardCvc', { style: { base: cvcAndExpiryStyle, invalid: { color: 'red' } } });

    card.mount('#card-number');
    exp.mount('#card-exp');
    cvc.mount('#card-cvc');

    card.on('change', (e) => {
      this.cardNumberIsValid = e.complete && !e.error;
      // this.cardNumberErrorMessage = e.error?.message;
    });

    exp.on('change', (e) => {
      this.expIsValid = e.complete && !e.error;
      // this.expErrorMessage = e.error?.message;
    });

    cvc.on('change', (e) => {
      this.cvcIsValid = e.complete && !e.error;
      // this.cvcErrorMessage = e.error?.message;
    });
  }
}