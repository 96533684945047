import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { elementBelongsToTarget } from 'src/app/core/shared/utils/utils';
import { BudgetBoxDetailsListComponent } from '../budget-box-details-list/budget-box-details-list.component';

@Component({
  selector: 'app-estimate-budget-box',
  templateUrl: './estimate-budget-box.component.html',
  styleUrls: ['./estimate-budget-box.component.scss']
})
export class EstimateBudgetBoxComponent implements OnInit {

  @Input('totalBudget') totalBudget: number;

  @ViewChild('projectBudgetInfo') projectBudgetInfo: ElementRef;
  @ViewChild('estimateBudgetBoxDetailsList') estimateBudgetBoxDetailsList: BudgetBoxDetailsListComponent;

  detailsAreOpened: boolean;

  budgets = [
    { name: 'Consultation', budget: '$600', invoice: '', completed: true },
    { name: 'Sprint 1', budget: '$250', invoice: '', completed: true },
    { name: 'Sprint 2', budget: '$2,500', invoice: '', completed: true },
    { name: 'Sprint 3', budget: '$2,500', invoice: '', completed: false },
    { name: 'Sprint 4', budget: '$1,500', invoice: '', completed: false },
    { name: 'Sprint 5', budget: '$5,500', invoice: '', completed: false },
    { name: 'Sprint 6', budget: '$5,500', invoice: '', completed: false },
    { name: 'Sprint 7', budget: '$5,500', invoice: '', completed: false },
  ];

  constructor() { }

  ngOnInit(): void {
    fromEvent(document, 'click')
      .subscribe((e) => {
        if (this.detailsAreOpened == true) {
          if (elementBelongsToTarget(e.target, this.projectBudgetInfo.nativeElement)) { this.toggleDetails(); }
        }
      })
  }

  toggleDetails() {
    this.estimateBudgetBoxDetailsList.toggleBudgetBody();
    this.detailsAreOpened = !this.detailsAreOpened;
  }
}
