<div class="user-menu-container" [style.height]="userMenuHeight + 'px'">
    <div class="user-menu-panel" #userMenuPanel>
        <div class="user-menu-header" (click)="toggleMenu()">
            <div class="project-details">
                <h6 class="project-name">{{ user?.project?.name }}</h6>
                <h5 class="project-label">Project Portal</h5>
            </div>
            <div class="user-profile">
                <div class="profile-notifications">
                    <div class="profile-notification yellow"></div>
                    <div class="profile-notification purple"></div>
                    <div class="profile-notification red"></div>
                </div>
                <img *ngIf="user?.avatar" [src]="user?.avatar" alt="">
                <div *ngIf="!user?.avatar" class="default-avatar">{{ user?.name | toInitials }}</div>
                <a class="menu-arrow" [ngClass]="{'opened' : menuIsOpen}"></a>
            </div>
        </div>

        <div class="user-menu-body">
            <h4 class="project-address">yachtnow.faststartup.io</h4>
            <div class="user-details">
                <h4 class="user-name f-14">{{ user?.name }}</h4>
                <h4 class="user-email">{{ user?.email }}</h4>
            </div>

            <div class="user-menu-settings">
                <nav>
                    <ul>
                        <li>
                            <app-menu-icon [name]="'settings'"></app-menu-icon>
                            <a (click)="settings()">Settings</a>
                        </li>
                        <li>
                            <app-menu-icon [name]="'helpAndFeedback'"></app-menu-icon>
                            <a>Help & Feedback</a>
                        </li>
                        <li>
                            <app-menu-icon [name]="'logOut'"></app-menu-icon>
                            <a (click)="logOut()">Log Out</a>
                        </li>
                        <li>
                            <app-fs-switch-checkbox [label]="'Do Not Disturb'"></app-fs-switch-checkbox>
                        </li>
                    </ul>
                </nav>
            </div>

            <div class="user-menu-projects">
                <h4>Your Projects</h4>

                <div class="project-list">
                    <div class="project">
                        <div class="project-details">
                            <h4>HEX</h4>
                            <h5>hex.faststartup.io</h5>
                        </div>
                        <div class="project-action">
                            <div class="button-pannel">
                                <div class="notifications-count info">122</div>
                                <button>

                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="project">
                        <div class="project-details">
                            <h4>pandemos</h4>
                            <h5>pandemos.faststartup.io</h5>
                        </div>
                        <div class="project-action">
                            <div class="button-pannel">
                                <div class="notifications-count warning">1</div>
                                <button>

                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="add-new-project">
                    <h5>Start a new project</h5>
                    <button></button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="user-profile-container">
    <div class="user-profile-content">
        <div class="media">
            <button (click)="uploadProfileImage()">
                <img *ngIf="user?.avatar" [src]="user?.avatar" alt="">
            </button>
        </div>
        <div class="user-info">
            <h3>{{ user?.name }}</h3>
            <h6>{{ user?.email }}</h6>
        </div>
        <div class="actions">
            <button (click)="toggleMenu()">
                <img src="../../../../assets/fs-settings.svg" alt="">
            </button>
        </div>
    </div>
    <div class="user-profile-menu" [style.height]="menuHeight">
        <div class="user-profile-menu-panel" #profileMenuPanel>
            <div class="user-profile-menu-content">
                <nav>
                    <ul>
                        <li>
                            <a [routerLink]="['/team']">
                                <i><img src="../../../../assets/menu/fs-invite-team-member.svg" alt=""></i>
                                Invite Team Member
                            </a>
                        </li>

                        <li>
                            <a [routerLink]="['/team']">
                                <i><img src="../../../../assets/menu/fs-team-management.svg" alt=""></i>
                                Team Management
                            </a>
                        </li>

                        <li>
                            <a [routerLink]="['/billing']">
                                <i><img src="../../../../assets/menu/fs-billing.svg" alt=""></i>
                                Billing
                            </a>
                        </li>

                        <li>
                            <a (click)="logOut()">
                                <i><img src="../../../../assets/menu/fs-logout.svg" alt=""></i>
                                Log Out
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="user-profile-menu-action">
                <button (click)="toggleMenu()">
                    <img src="../../../../assets/fs-settings-active.svg" alt="">
                </button>
            </div>
        </div>
    </div>
</div> -->