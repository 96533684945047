<section #selectorContent [ngClass]="{'opened' : contentIsShown}">
    <div class="header" (click)="toggle()">
        <div class="select-item">
            <span *ngIf="selectedItem?.value">{{ selectedItem?.value }}</span>
            <span *ngIf="!selectedItem?.value && label">{{ label }}</span>
        </div>
        <app-fs-triangle-arrow-button></app-fs-triangle-arrow-button>
    </div>
    <div class="content" [style.height]="contentHeight + 'px'">
        <div class="content-panel" #contentPanel>

            <!-- <div class="search-panel">
                <input type="text" [(ngModel)]="searchString" (keyup)="searchKeyUp()">
            </div> -->

            <div class="select-items">
                <div class="select-item" *ngFor="let item of unselectedItems" (click)="selectItem(item)">
                    {{ item.value }}
                </div>
            </div>

            <div class="no-records" *ngIf="unselectedItems.length == 0">
                <label class="label-1 text-center">No Records</label>
            </div>
        </div>
    </div>
</section>