<div class="budget-body-container" [style.height]="budgetBodyHeight + 'px'">
    <div class="budget-body-panel" #budgetBodyPanel>

        <div class="budget-list">
            <div class="budget-list-header pl-m pr-m">
                <label class="label-1 text-uppercase">Name</label>
                <label class="label-1 text-uppercase budget">Budget</label>
                <label class="label-1 text-uppercase">Invoice</label>
            </div>
            <div class="budget-list-body">
                <div class="budget-item pl-m pr-m" *ngFor="let budget of budgets">
                    <label class="label-1 fw-medium">{{ budget.name }}</label>
                    <label class="label-1 budget"
                        [ngClass]="{'completed' : budget.completed, 'fw-medium' : budget.completed}">
                        {{ budget.budget }} <i> <img src="../../../../assets/fs-budget-list-checked.svg"> </i>
                    </label>
                    <label class="label-1">
                        <a class="fw-medium" *ngIf="budget.completed">
                            <img src="../../../../assets/fs-download-invoice.svg" alt="">
                            Download
                        </a>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>